//!you may need to change record.id to record.key

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useTable from "../../../../MainComponents/Hooks/useTable";
import toast from "react-hot-toast";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";

function Codes({ activation }) {
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [generateCodesSuccess, generateCodesErrors, submitLoading] = useAxios(
    process.env.REACT_APP_GENERATE_CODES_API,
    "POST",
    submitGenerate.flag,
    submitGenerate.dependency,
    submitGenerate.data,
    true
  );
  const table = useTable("codes", "", "", generateCodesSuccess?.data);
  const [border, setBorder] = useState(false);
  const AuthState = useAuthState();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "generateCodesRequest",
      dependency: !submitGenerate.dependency,
      data: data,
    });
  };
  useEffect(() => {
    //! reset the form on success submit

    if (generateCodesSuccess) {
      setBorder(true);
      reset();
    }
  }, [generateCodesSuccess]);

  async function copy(code) {
    try {
      await navigator.clipboard.writeText(code);
      toast.success("تم النسخ إلى الحافظة");
    } catch (err) {
      console.error("Failed to copy: ", err);
      toast.error("النسخ إلى الحافظة فشل من فضلك حاول مرة اخري");
    }
  }

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="الاكواد | مسيو بلال عبدالله "></HelmetTags>{" "}
      <h2 className="w-full text-end text-4xl font-bold md:text-center">
        الأكواد
      </h2>
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("code_add")
        )) && (
        <div className="form-codes-container my-20">
          <h2 className="my-20 text-center text-3xl font-semibold">
            من فضلك قم بملئ البيانات للإستخراج
            {activation ? "activation codes" : " الأكواد"}
          </h2>
          <div className="form-codes flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-start ">
            <div
              className={`codes flex h-auto max-h-[380px]  w-1/2 md:w-full flex-wrap items-center justify-center gap-7 overflow-y-auto p-4 ${
                border ? "rounded-xl border-2 border-secondary" : ""
              } `}
            >
              {generateCodesSuccess?.data?.map((code) => (
                <button className="code-btn" onClick={() => copy(code)}>
                  {code}
                </button>
              ))}
            </div>
            <form
              method="post"
              onSubmit={handleSubmit(onSubmit)}
              className="flex h-fit w-full flex-col  items-center justify-start gap-6 rounded-3xl bg-secondary p-10 shadow-lg shadow-red-200/30  "
            >
              <div className="first-row flex w-full items-center justify-between gap-6 md:flex-col md:justify-start">
                {/** number of codes field */}

                <div className=" flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
                  <label className="w-full truncate text-white" htmlFor="count">
                    عدد الأكواد{" "}
                  </label>
                  <input
                    className="signin-inputs  w-full "
                    type="number"
                    min={1}
                    id="count"
                    placeholder="000"
                    name="count"
                    autoComplete="on"
                    {...register("count", {
                      required: true,
                      valueAsNumber: true,
                    })}
                  />

                  {errors.count && (
                    <p className="text-[12px] text-white ">
                      {errors.count.type === "required" &&
                        "برجاء ملئ هذا الحقل"}{" "}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    generateCodesErrors &&
                      generateCodesErrors?.response?.data?.errors?.count && (
                        <p className="w-full text-end text-[12px] text-white  ">
                          {
                            generateCodesErrors?.response?.data?.errors
                              ?.count[0]
                          }
                        </p>
                      )
                  }
                </div>

                {/** length of code field */}

                <div className=" flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
                  <label
                    className="w-full truncate  text-white"
                    htmlFor="length"
                  >
                    عدد أحرف الكود{" "}
                  </label>
                  <input
                    className="signin-inputs w-full "
                    type="number"
                    min={1}
                    max={255}
                    id="length"
                    placeholder="000"
                    name="length"
                    autoComplete="on"
                    {...register("length", {
                      required: true,
                      valueAsNumber: true,
                      max: 255,
                      min: 1,
                    })}
                  />

                  {errors.length && (
                    <p className="text-[12px] text-white ">
                      {errors.length.type === "required" &&
                        "برجاء ملئ هذا الحقل"}{" "}
                      {errors.length.type === "max" &&
                        "أقصي عدد للحروف هو ٢٢٥ حرف"}
                      {errors.length.type === "min" &&
                        " من فضلك قم بكتابة حرف واحد علي الاقل"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    generateCodesErrors &&
                      generateCodesErrors?.response?.data?.errors?.length && (
                        <p className="w-full text-end text-[12px] text-white  ">
                          {
                            generateCodesErrors?.response?.data?.errors
                              ?.length[0]
                          }
                        </p>
                      )
                  }
                </div>
              </div>

              <div className="second-row flex w-full items-center justify-between gap-6 md:flex-col md:justify-start">
                {/** Price of codes field */}

                {activation ? (
                  ""
                ) : (
                  <div className=" flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
                    <label
                      className="w-full truncate text-white"
                      htmlFor="price"
                    >
                      سعر الكود{" "}
                    </label>
                    <input
                      className="signin-inputs w-full "
                      type="number"
                      min={1}
                      id="price"
                      placeholder="000"
                      name="price"
                      autoComplete="on"
                      {...register("price", {
                        required: true,
                        valueAsNumber: true,
                      })}
                    />

                    {errors.price && (
                      <p className="text-[12px] text-white ">
                        {errors.price.type === "required" &&
                          "برجاء ملئ هذا الحقل"}{" "}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      generateCodesErrors &&
                        generateCodesErrors?.response?.data?.errors?.price && (
                          <p className="w-full text-end text-[12px] text-white  ">
                            {
                              generateCodesErrors?.response?.data?.errors
                                ?.price[0]
                            }
                          </p>
                        )
                    }
                  </div>
                )}

                {/** Type of codes field */}

                <div
                  className={`flex flex-col items-end justify-center gap-2  ${
                    activation ? "w-full" : "w-1/2"
                  }  md:w-full`}
                >
                  <label className="w-full truncate text-white" htmlFor="type">
                    نوع الأكواد{" "}
                  </label>
                  <select
                    name="type"
                    id="type"
                    {...register("type", { required: true })}
                  >
                    <option value="char">أحرف فقط</option>
                    <option value="numbers">أرقام فقط</option>
                    <option value="mixed">أحرف و أرقام</option>
                  </select>

                  {errors.type && (
                    <p className="text-[12px] text-white ">
                      {errors.type.type === "required" && "برجاء ملئ هذا الحقل"}{" "}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    generateCodesErrors &&
                      generateCodesErrors?.response?.data?.errors?.type && (
                        <p className="w-full text-end text-[12px] text-white  ">
                          {generateCodesErrors?.response?.data?.errors?.type[0]}
                        </p>
                      )
                  }
                </div>
              </div>

              {/** submit */}
              <button
                disabled={!isValid || (submitLoading && submitGenerate.flag)}
                className=" submit mt-6 w-full "
                type="submit"
              >
                {submitLoading && submitGenerate.flag ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "استخراج"
                )}
              </button>
            </form>
          </div>
        </div>
      )}
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Codes;
