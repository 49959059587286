import React from "react";

import StudentChangePassword from "./components/StudentChangePassword";
import StudentChangeImage from "./components/StudentChangeImage";
import QRCode from "react-qr-code";
import StudentQrCode from "./components/StudentQrCode";

const Profile = () => {
  return (
    <section className="h-auto width flex flex-col items-center">
      <StudentQrCode />

      <StudentChangeImage />
      <StudentChangePassword />
    </section>
  );
};

export default Profile;
