import { useEffect, useReducer, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import logoLight from "../../../assets/logo-light.png";
function Register({ edit, StudentInfo }) {
  const [values, setValues] = useState(null);
  const { studentID } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    values,
  });

  const watchLevel = watch("stage");
  const watchPlace = watch("is_online");
  function reducer(state, action) {
    switch (action.type) {
      case "setGroups": {
        return {
          ...state,
          groups: action.payload,
        };
      }
      case "setSelectedState": {
        return {
          ...state,
          selectedState: action.payload,
        };
      }
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setHide": {
        return {
          ...state,
          hide: !state.hide,
        };
      }
      case "setSubmitLoading": {
        return {
          ...state,
          submitLoading: action.payload,
        };
      }
      case "setSubmitEditStudent": {
        return {
          ...state,
          submitEditStudent: action.payload,
        };
      }
      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    hide: false,
    groups: [],
    selectedState: "",
    errors: {},
    submitLoading: false,
    submitEditStudent: { flag: "", dependency: false, data: {} },
  });

  const [StudentEditSuccess, StudentEditErrors, StudentEditLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_INFO_API,
    "POST",
    state.submitEditStudent.flag,
    state.submitEditStudent.dependency,
    state.submitEditStudent.data,
    true
  );

  useEffect(() => {
    if (StudentEditSuccess) {
      reset();
      navigate(-1);
    }
  }, [StudentEditSuccess]);

  useEffect(() => {
    // const stateSelector = document.querySelector(".state-selector");
    // const citySelector = document.querySelector(".city-selector");
    // stateSelector.addEventListener("change", function handleStateSelected(e) {
    //   dispatch({
    //     type: "setSelectedState",
    //     payload: e.target.selectedIndex,
    //   });
    // });
    // citySelector?.addEventListener("change", function handleStateSelected(e) {
    //   dispatch({
    //     type: "setCitySelected",
    //     payload: e.target.selectedIndex,
    //   });
    // });
    async function fetchData() {
      try {
        const res = await axios(process.env.REACT_APP_GOVERNORATES_API);
        const data = await res.data;

        dispatch({
          type: "setGroups",
          payload: data?.groups,
        });
      } catch (error) {
        toast.error(
          "An error occurred while loading cities. Please try again."
        );
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (StudentInfo) {
      dispatch({
        type: "setSelectedState",
        payload: StudentInfo?.data[0]?.selected_state,
      });
      setValue("governorate", StudentInfo?.data[0]?.governorate);
      setValue("is_online", StudentInfo?.data[0]?.is_online);
      setValue("stage", StudentInfo?.data[0]?.stage);
      delete StudentInfo?.data[0]?.governorate;
      delete StudentInfo?.data[0]?.is_online;
      delete StudentInfo?.data[0]?.stage;
      setValues({
        ...StudentInfo?.data[0],
        active: StudentInfo?.data[0]?.active === 1 ? true : false,
      });
    }
  }, [StudentInfo]);

  const onSubmit = (data) => {
    const studentData = edit
      ? {
          ...data,
          group_id: data?.group_id || null,
          // profile_pic: data?.profile_pic[0] || null,
          magnetic_id: data?.magnetic_id || null,
          active: data?.active === true ? 1 : 0,
          id: studentID,
        }
      : {
          ...data,
        };

    if (edit) {
      dispatch({
        type: "setSubmitEditStudent",
        payload: {
          flag: "AddEditStudent",
          dependency: !state.submitEditStudent.dependency,
          data: studentData,
        },
      });
    } else {
      dispatch({
        type: "setSubmitLoading",
        payload: true,
      });
      async function postData() {
        try {
          const res = await axios.post(
            process.env.REACT_APP_REGISTER_API,
            studentData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );

          const data = await res.data;
          toast.success(data?.message);
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          !edit && navigate("/signin");
          reset();
        } catch (error) {
          dispatch({
            type: "setErrors",
            payload: error.response.data?.errors,
          });
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          toast.error(
            "حدث خطأ اثناء انشاء حساب جديد من فضلك قم بمراجعة البيانات "
          );
        }
      }

      postData();
    }
  };

  return (
    <section className="flex h-fit min-h-screen flex-col items-center justify-center gap-0 w-full py-24 dark:bg-dark">
      {!edit && (
        <>
          <HelmetTags
            title="انشاء حساب | مسيو بلال عبدالله "
            description="انشاء حساب | مسيو بلال عبدالله "
            index
          >
            <link rel="canonical" href="https://motazradwan.com/register" />
          </HelmetTags>

          <div className="mb-24 flex flex-col items-center  justify-center">
            <Link className="h-[100px] text-4xl " to="/">
              <img className="h-full w-full" src={logoLight} alt="logo" />
            </Link>
            <h1 className="mb-5 mt-10 font-bold ">
              حساب جديد | مسيو بلال عبدالله
            </h1>
            <p className=" opacity-80">
              أدخل التفاصيل الخاصة بك لإنشاء حساب جديد
            </p>
          </div>
        </>
      )}
      <form
        encType="multipart/form-data"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-6 rounded-3xl bg-secondary text-white p-16 shadow-lg shadow-red-200/50"
      >
        <div className="flex w-full items-start justify-between gap-6 md:flex-col ">
          {/** First Names filed */}
          <div className="flex w-full items-start justify-start gap-6  md:w-full md:flex-col">
            {/** First Name filed */}
            <div className=" flex w-full flex-col items-start justify-center  gap-2">
              <label htmlFor="name">الإسم ثلاثي (إجباري)</label>
              {/* !bg-red-800 */}
              <input
                className={`signin-inputs w-full pl-4 ${
                  errors.name ? "border-red-600" : ""
                }`}
                type="text"
                id="name"
                placeholder="الإسم "
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  pattern: /^[\p{Script=Arabic}\s]+$/u,
                  maxLength: 255,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full text-end text-[12px] text-white  ">
                  {errors.name.type === "required" && "برجاء ملئ هذا الحقل"}
                  {errors.name?.type === "pattern" &&
                    "يسمح فقط باستخدام الحروف العربية "}
                  {errors.name.type === "maxLength" &&
                    "أقصي عدد للحروف هو 15 حرف"}
                  {errors.name.type === "minLength" &&
                    "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                </p>
              )}

              {
                //!-------server errors -----

                state.errors && state.errors.name && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {state.errors.name[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full text-end text-[12px] text-white  ">
                      {StudentEditErrors?.response?.data.errors.name[0]}
                    </p>
                  )
              }
            </div>
          </div>
        </div>

        {edit && (
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6 ">
            {/** student WhatsUp field */}

            <div className="w-full md:order-1 md:w-full">
              <div className="  flex w-full flex-col items-start justify-center gap-2">
                <label htmlFor="mobile">الموبايل</label>
                <div className="relative w-full">
                  <input
                    className={`signin-inputs w-full ${
                      state.errors.mobile && "!border-red-600"
                    }`}
                    type="text"
                    id="mobile"
                    placeholder="الموبايل"
                    name="mobile"
                    autoComplete="on"
                    {...register("mobile", {
                      required: true,
                      pattern: /^[\d]{11}/,
                      maxLength: 11,
                    })}
                  />
                  <FontAwesomeIcon
                    className="absolute bottom-1/2 left-4 h-6  w-7 translate-y-1/2"
                    icon={faWhatsapp}
                  />
                </div>
              </div>
              {errors.mobile && (
                <p className="mt-2 w-full text-end text-[12px] text-white">
                  {errors.mobile.type === "required" && "برجاء ملئ هذا الحقل"}
                  {errors.mobile.type === "pattern" &&
                    "  من فضلك ادخل رقم هاتف مكون من ١١ رقم"}
                  {errors.mobile.type === "maxLength" &&
                    "  من فضلك ادخل رقم هاتف مكون من ١١ رقم"}
                </p>
              )}

              {
                //!-------server errors -----

                state.errors && state.errors.mobile && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {state.errors.mobile[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.mobile && (
                    <p className="w-full text-end text-[12px] text-white  ">
                      {StudentEditErrors?.response?.data.errors.mobile[0]}
                    </p>
                  )
              }
            </div>
          </div>
        )}

        {/** Father's Mobile Numbers fields */}
        <div className="flex  w-full items-start justify-between gap-16 md:flex-col md:gap-6">
          {/** Father's WhatsUp field 1 */}
          <div className={`${edit ? "w-full" : "w-1/2"} md:order-2 md:w-full`}>
            <div className="flex w-full flex-col items-start justify-center gap-2">
              <label htmlFor="parent_mobile">(إجباري) رقم ولي الأمر </label>
              <div className="relative w-full">
                <input
                  className={`signin-inputs w-full ${
                    state.errors.parent_mobile || errors.parent_mobile
                      ? "!border-red-600"
                      : null
                  }`}
                  type="number"
                  id="parent_mobile"
                  placeholder="رقم ولي الأمر "
                  name="parent_mobile"
                  autoComplete="on"
                  {...register("parent_mobile", {
                    required: true,
                    pattern: /^[\d]{11}/,
                    maxLength: 11,
                  })}
                />
                <FontAwesomeIcon
                  className="absolute bottom-1/2 left-4 h-6  w-7 translate-y-1/2"
                  icon={faWhatsapp}
                />
              </div>
            </div>
            {errors.parent_mobile && (
              <p className="mt-2 w-full text-end text-[12px] text-white">
                {errors.parent_mobile.type === "required" &&
                  "برجاء ملئ هذا الحقل"}
                {errors.parent_mobile.type === "pattern" &&
                  "  من فضلك ادخل رقم هاتف مكون من ١١ رقم"}
                {errors.parent_mobile.type === "maxLength" &&
                  "  من فضلك ادخل رقم هاتف مكون من ١١ رقم"}
              </p>
            )}

            {
              //!-------server errors -----

              state.errors && state.errors.parent_mobile && (
                <p className="w-full text-end text-[12px] text-white  ">
                  {state.errors.parent_mobile[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.parent_mobile && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {StudentEditErrors?.response?.data.errors.parent_mobile[0]}
                  </p>
                )
            }
          </div>
          {/** student WhatsUp field */}
          {!edit && (
            <div className="w-1/2 md:order-1 md:w-full">
              <div className="  flex w-full flex-col items-start justify-center gap-2">
                <label htmlFor="mobile">(إجباري) الموبايل</label>
                <div className="relative w-full">
                  <input
                    className={`signin-inputs w-full ${
                      state.errors.mobile || errors.mobile
                        ? "!border-red-600"
                        : null
                    }`}
                    type="number"
                    id="mobile"
                    placeholder="الموبايل"
                    name="mobile"
                    autoComplete="on"
                    {...register("mobile", {
                      required: true,
                      pattern: /^[\d]{11}/,
                      maxLength: 11,
                    })}
                  />
                  <FontAwesomeIcon
                    className="absolute bottom-1/2 left-4 h-6  w-7 translate-y-1/2"
                    icon={faWhatsapp}
                  />
                </div>
              </div>
              {errors.mobile && (
                <p className="mt-2 w-full text-end text-[12px] text-white">
                  {errors.mobile.type === "required" && "برجاء ملئ هذا الحقل"}
                  {errors.mobile.type === "pattern" &&
                    "  من فضلك ادخل رقم هاتف مكون من ١١ رقم"}
                  {errors.mobile.type === "maxLength" &&
                    "  من فضلك ادخل رقم هاتف مكون من ١١ رقم"}
                </p>
              )}

              {
                //!-------server errors -----

                state.errors && state.errors.mobile && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {state.errors.mobile[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.mobile && (
                    <p className="w-full text-end text-[12px] text-white  ">
                      {StudentEditErrors?.response?.data.errors.mobile[0]}
                    </p>
                  )
              }
            </div>
          )}
        </div>

        {/** Username and passwords fields */}
        {!edit && (
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6 ">
            {/** Confirm Password  */}
            <div className="w-1/2 md:order-3 md:w-full">
              <div className="relative flex flex-col items-start justify-center gap-2 md:order-3 ">
                <label htmlFor="password_confirmation">
                  {" "}
                  (إجباري) تأكيد كلمه المرور
                </label>
                <div
                  //!input password relative
                  className="relative-hide relative h-auto w-full"
                >
                  <input
                    id="password_confirmation"
                    className={`signin-inputs w-full ${
                      state.errors.password_confirmation ||
                      errors.password_confirmation
                        ? "!border-red-600"
                        : null
                    }`}
                    type={`${state.hide ? "text" : "password"}`}
                    placeholder="تأكيد كلمه المرور"
                    name="password_confirmation"
                    autoComplete="on"
                    {...register("password_confirmation", {
                      required: true,
                      maxLength: 25,
                      minLength: 6,
                      validate: (value) => value === getValues("password"),
                    })}
                  />

                  <div
                    //!eye icons
                    onClick={() =>
                      dispatch({
                        type: "setHide",
                      })
                    }
                    className="hide-pass absolute bottom-1/2 left-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                  >
                    {state.hide ? (
                      <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                    ) : (
                      <FontAwesomeIcon
                        className="h-full w-full"
                        icon={faEyeSlash}
                      />
                    )}
                  </div>
                </div>
              </div>

              {errors.password_confirmation && (
                <p className="mt-2 w-full text-end text-[12px] text-white">
                  {errors.password_confirmation.type === "required" &&
                    "برجاء ملئ هذا الحقل"}
                  {errors.password_confirmation.type === "maxLength" &&
                    "أقصي عدد للحروف هو ٢٥ حرف"}
                  {errors.password_confirmation.type === "minLength" &&
                    "أقل عدد للحروف هو ٦ أحرف"}
                  {errors.password_confirmation.type === "validate" &&
                    "كلمات المرور غير متطابقة"}
                </p>
              )}

              {
                //!-------server errors -----

                state.errors && state.errors.password_confirmation && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {state.errors.password_confirmation[0]}
                  </p>
                )
              }
            </div>
            {/** Password  */}
            <div className="w-1/2 md:order-2 md:w-full">
              <div className="relative flex flex-col items-start justify-center gap-2 ">
                <label htmlFor="password"> (إجباري) كلمه المرور</label>
                <div
                  //!input password relative
                  className="relative-hide relative h-auto w-full"
                >
                  <input
                    id="password"
                    className={`signin-inputs w-full ${
                      state.errors.password || errors.password
                        ? "!border-red-600"
                        : null
                    }`}
                    type={`${state.hide ? "text" : "password"}`}
                    placeholder="كلمه المرور"
                    name="password"
                    autoComplete="on"
                    {...register("password", {
                      required: true,
                      maxLength: 25,
                      minLength: 6,
                    })}
                  />

                  <div
                    //!eye icons
                    onClick={() =>
                      dispatch({
                        type: "setHide",
                      })
                    }
                    className="hide-pass absolute bottom-1/2 left-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                  >
                    {state.hide ? (
                      <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                    ) : (
                      <FontAwesomeIcon
                        className="h-full w-full"
                        icon={faEyeSlash}
                      />
                    )}
                  </div>
                </div>
              </div>
              {errors.password && (
                <p className="mt-2 w-full text-end text-[12px] text-white">
                  {errors.password.type === "required" && "برجاء ملئ هذا الحقل"}
                  {errors.password.type === "maxLength" &&
                    "أقصي عدد للحروف هو ٢٥ حرف"}
                  {errors.password.type === "minLength" &&
                    "أقل عدد للحروف هو ٦ أحرف"}
                </p>
              )}

              {
                //!-------server errors -----

                state.errors && state.errors.password && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {state.errors.password[0]}
                  </p>
                )
              }
            </div>
          </div>
        )}

        {/** Academic Level  */}
        <div className=" flex flex-col items-start justify-center gap-2 w-full">
          <label htmlFor="stage"> (إجباري) الصف الدراسي</label>
          <select
            name="stage"
            id="stage"
            className={` ${state.errors.stage && "!border-red-600"}`}
            {...register("stage", { required: true })}
          >
            <option disabled value="" selected>
              الصف الدراسي
            </option>
            <option value="11">الأول الاعدادي</option>
            <option value="22">الثاني الاعدادي</option>
            <option value="33">الثالث الاعدادي</option>

            <option value="1">الأول الثانوي</option>
            <option value="2">الثاني الثانوي</option>
            <option value="3">الثالث الثانوي</option>
          </select>
          {errors.stage && (
            <p className="w-full text-end text-[12px] text-white  ">
              {errors.stage.type === "required" && "برجاء ملئ هذا الحقل"}
            </p>
          )}
          {
            //!-------server errors -----

            state.errors && state.errors.stage && (
              <p className="w-full text-end text-[12px] text-white  ">
                {state.errors.stage[0]}
              </p>
            )
          }{" "}
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.stage && (
                <p className="w-full text-end text-[12px] text-white  ">
                  {StudentEditErrors?.response?.data.errors.stage[0]}
                </p>
              )
          }
        </div>

        {/** groups and center or group  fields */}
        <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6">
          {/** groups filed */}
          <div className=" flex w-1/2 flex-col items-start justify-center gap-2 md:order-2 md:w-full">
            {watchPlace === "0" || watchPlace === 0 ? (
              <>
                <label htmlFor="group_id"> (إجباري) الجروب</label>
                <select
                  name="group_id"
                  id="group_id"
                  className={` ${state.errors.group_id && "!border-red-600"}`}
                  {...register("group_id", { required: edit ? false : true })}
                >
                  {state.groups
                    ?.filter(
                      (group) => parseInt(group.stage) === parseInt(watchLevel)
                    )
                    .map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                </select>

                {errors.group_id && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {errors.group_id.type === "required" &&
                      "برجاء ملئ هذا الحقل"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.group_id && (
                    <p className="w-full text-end text-[12px] text-white  ">
                      {state.errors.group_id[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.group_id && (
                      <p className="w-full text-end text-[12px] text-white  ">
                        {StudentEditErrors?.response?.data.errors.group_id[0]}
                      </p>
                    )
                }
              </>
            ) : (
              ""
            )}
          </div>
          {/** center or group */}

          <div className=" flex w-1/2 flex-col items-start justify-center gap-2 md:w-full ">
            <label htmlFor="is_online"> (إجباري) المكان</label>
            <select
              name="is_online"
              id="is_online"
              className={` ${state.errors.fourth_name && "!border-red-600"}`}
              {...register("is_online", { required: true })}
            >
              <option value="1">اونلاين</option>
              <option value="0">سنتر</option>
            </select>
            {errors.is_online && (
              <p className="w-full text-end text-[12px] text-white  ">
                {errors.is_online.type === "required" && "برجاء ملئ هذا الحقل"}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.is_online && (
                <p className="w-full text-end text-[12px] text-white  ">
                  {state.errors.is_online[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.is_online && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {StudentEditErrors?.response?.data.errors.is_online[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** active and magnetic_id fields */}
        {edit && (
          <div className="flex w-full items-center justify-between gap-16 md:flex-col md:gap-6">
            {/** active filed */}
            <div className=" active flex w-1/2 flex-col items-end justify-center gap-2 md:w-full ">
              <div className="flex w-full items-center justify-start gap-10 md:justify-center">
                <label className="visibility-switch">
                  <input
                    className={`${state.errors.active && "!border-red-600"}`}
                    id="active"
                    name="active"
                    {...register("active", {
                      required: false,
                    })}
                    type="checkbox"
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate"
                    htmlFor="active"
                  >
                    التفعيل{" "}
                  </label>
                </div>
              </div>

              {errors.active && (
                <p className="text-[12px] text-white ">
                  {errors.active.type === "required" && "برجاء ملئ هذا الحقل"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.active && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {state.errors.active[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.active && (
                    <p className="w-full text-end text-[12px] text-white  ">
                      {StudentEditErrors?.response?.data.errors.active[0]}
                    </p>
                  )
              }
            </div>
            {/**magnetic_id */}
            <div className=" flex w-1/2 flex-col items-start justify-center  gap-2  md:order-1 md:w-full">
              <label htmlFor="magnetic_id">كارت التفعيل</label>
              <input
                className={`signin-inputs w-full ${
                  state.errors && state.errors.magnetic_id && "!border-red-600"
                }`}
                type="text"
                id="magnetic_id"
                placeholder="كارت التفعيل"
                name="magnetic_id"
                autoComplete="on"
                {...register("magnetic_id", {
                  pattern: /^[A-Za-z0-9-_]*$/,
                  minLength: 3,
                })}
              />
              {errors.magnetic_id && (
                <p className="w-full text-end text-[12px] text-white  ">
                  {errors.magnetic_id.type === "required" &&
                    "برجاء ملئ هذا الحقل"}
                  {errors.magnetic_id.type === "pattern" &&
                    " يسمح فقط باستخدام الحروف الإنجليزية والأرقام"}
                  {errors.magnetic_id.type === "maxLength" &&
                    "أقصي عدد للحروف هو ٢٠ حرف"}
                  {errors.magnetic_id.type === "minLength" &&
                    " من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.magnetic_id && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {state.errors.magnetic_id[0]}
                  </p>
                )
              }{" "}
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.magnetic_id && (
                    <p className="w-full text-end text-[12px] text-white  ">
                      {StudentEditErrors?.response?.data.errors.magnetic_id[0]}
                    </p>
                  )
              }
            </div>
          </div>
        )}
        {/** School and Activation Code fields */}
        <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6">
          {/** code  */}
          {/* <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2">
            <label htmlFor="code"> الكود</label>
            <input
              className={`signin-inputs w-full ${
                state.errors && state.errors.code && "!border-red-600"
              }`}
              type="number"
              id="code"
              placeholder="الكود"
              name="code"
              autoComplete="on"
              {...register("code", {
                required: false,
                maxLength: 35,
                minLength: 2,
              })}
            />
            {errors.code && (
              <p className="w-full text-end text-[12px] text-white  ">
                {errors.code.type === "required" && "برجاء ملئ هذا الحقل"}

                {errors.code.type === "maxLength" &&
                  "أقصي عدد للاقام هو ٣٥ حرف"}
                {errors.code.type === "minLength" &&
                  " من فضلك قم بكتابة ٢ أرقام علي الاقل"}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.code && (
                <p className="w-full text-end text-[12px] text-white  ">
                  {state.errors.code[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.code && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {StudentEditErrors?.response?.data.errors.code[0]}
                  </p>
                )
            }
          </div> */}
          {/** School  */}
          <div className=" flex w-full md:w-full flex-col items-start justify-center gap-2">
            <label htmlFor="school">(إجباري) المدرسة</label>
            <input
              className={`signin-inputs w-full ${
                state.errors && state.errors.school && "!border-red-600"
              }`}
              type="text"
              id="school"
              placeholder="المدرسة"
              name="school"
              autoComplete="on"
              {...register("school", {
                required: true,
                maxLength: 35,
                minLength: 4,
              })}
            />
            {errors.school && (
              <p className="w-full text-end text-[12px] text-white  ">
                {errors.school.type === "required" && "برجاء ملئ هذا الحقل"}

                {errors.school.type === "maxLength" &&
                  "أقصي عدد للحروف هو ٣٥ حرف"}
                {errors.school.type === "minLength" &&
                  " من فضلك قم بكتابة ٤ أحرف علي الاقل"}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.school && (
                <p className="w-full text-end text-[12px] text-white  ">
                  {state.errors.school[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.school && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {StudentEditErrors?.response?.data.errors.school[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** Submit Button */}

        <button
          disabled={!isValid || state.submitLoading}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {state.submitLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : edit ? (
            "تعديل البيانات"
          ) : (
            "إنشاء حساب"
          )}
        </button>
      </form>
      {!edit && (
        <div className="my-10 w-full text-center text-[14px]  ">
          لديك حساب بالفعل؟{" "}
          <Link className=" font-bold" to="/signin">
            قم بتسجيل الدخول الآن
          </Link>
        </div>
      )}
    </section>
  );
}

export default Register;
