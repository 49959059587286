import QRCode from "react-qr-code";
import { useAuthState } from "../../../../MainComponents/GlobalContext";

const StudentQrCode = () => {
  const AuthState = useAuthState();

  return (
    <div className="w-[150px] h-[150px] mt-20">
      <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={`${AuthState?.userData?.student?.id}`}
        viewBox={`0 0 256 256`}
      />
    </div>
  );
};

export default StudentQrCode;
