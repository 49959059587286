import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import Register from "../../../Student/SignIn/Register.jsx";
import { useEffect, useReducer, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import QRCode from "react-qr-code";
function PasswordForm() {
  const [hide, setHide] = useState(false);
  const { studentID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitEditPassword": {
        return {
          ...state,
          submitEditPassword: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitEditPassword: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [EditPasswordSuccess, EditPasswordErrors, PasswordLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_PASSWORD_API,
    "POST",
    state.submitEditPassword.flag,
    state.submitEditPassword.dependency,
    state.submitEditPassword.data,
    true
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitEditPassword",
      payload: {
        flag: "submitEditPassword",
        dependency: !state.submitEditPassword.dependency,
        data: { ...data, id: studentID },
      },
    });
  };
  return (
    <form
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-6 rounded-3xl bg-secondary text-white p-16 shadow-lg shadow-red-200/50 mt-24"
    >
      <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6 ">
        {/** Confirm Password  */}

        <div className="w-1/2 md:order-3 md:w-full">
          <div className="relative flex flex-col items-start justify-center gap-2 md:order-3 ">
            <label htmlFor="password_confirmation">تأكيد كلمه المرور</label>

            <div
              //!input password relative
              className="relative-hide relative h-auto w-full"
            >
              <input
                id="password_confirmation"
                className="signin-inputs  w-full"
                type={`${hide ? "text" : "password"}`}
                placeholder="تأكيد كلمه المرور"
                name="password_confirmation"
                autoComplete="on"
                {...register("password_confirmation", {
                  required: true,
                  maxLength: 25,
                  minLength: 6,
                  validate: (value) => value === getValues("password"),
                })}
              />

              <div
                //!eye icons
                onClick={() => setHide(!hide)}
                className="hide-pass absolute bottom-1/2 left-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
              >
                {hide ? (
                  <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                ) : (
                  <FontAwesomeIcon
                    className="h-full w-full"
                    icon={faEyeSlash}
                  />
                )}
              </div>
            </div>
          </div>

          {errors.password_confirmation && (
            <p className="mt-2 w-full text-end text-[12px] text-white">
              {errors.password_confirmation.type === "required" &&
                "برجاء ملئ هذا الحقل"}
              {errors.password_confirmation.type === "maxLength" &&
                "أقصي عدد للحروف هو ٢٥ حرف"}
              {errors.password_confirmation.type === "minLength" &&
                "أقل عدد للحروف هو ٦ أحرف"}
              {errors.password_confirmation.type === "validate" &&
                "كلمات المرور غير متطابقة"}
            </p>
          )}

          {
            //!-------Editing server errors -----

            EditPasswordErrors?.response?.data?.errors &&
              EditPasswordErrors?.response?.data?.errors
                ?.password_confirmation && (
                <p className="w-full text-end text-[12px] text-rewhite">
                  {
                    EditPasswordErrors?.response?.data.errors
                      .password_confirmation[0]
                  }
                </p>
              )
          }
        </div>
        {/** Password  */}

        <div className="w-1/2 md:order-2 md:w-full">
          <div className="relative flex flex-col items-start justify-center gap-2 ">
            <label htmlFor="password">كلمه المرور</label>
            <div
              //!input password relative
              className="relative-hide relative h-auto w-full"
            >
              <input
                id="password"
                className="signin-inputs  w-full"
                type={`${hide ? "text" : "password"}`}
                placeholder="كلمه المرور"
                name="password"
                autoComplete="on"
                {...register("password", {
                  required: true,
                  maxLength: 25,
                  minLength: 6,
                })}
              />

              <div
                //!eye icons
                onClick={() => setHide(!hide)}
                className="hide-pass absolute bottom-1/2 left-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
              >
                {hide ? (
                  <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                ) : (
                  <FontAwesomeIcon
                    className="h-full w-full"
                    icon={faEyeSlash}
                  />
                )}
              </div>
            </div>
          </div>
          {errors.password && (
            <p className="mt-2 w-full text-end text-[12px] text-white">
              {errors.password.type === "required" && "برجاء ملئ هذا الحقل"}
              {errors.password.type === "maxLength" &&
                "أقصي عدد للحروف هو ٢٥ حرف"}
              {errors.password.type === "minLength" &&
                "أقل عدد للحروف هو ٦ أحرف"}
            </p>
          )}

          {
            //!-------Editing server errors -----

            EditPasswordErrors?.response?.data?.errors &&
              EditPasswordErrors?.response?.data?.errors?.password && (
                <p className="w-full text-end text-[12px] text-rewhite">
                  {EditPasswordErrors?.response?.data.errors.password[0]}
                </p>
              )
          }
        </div>
      </div>
      {/** Submit Button */}

      <button
        disabled={
          !isValid || (state.submitEditPassword.flag && PasswordLoading)
        }
        className=" submit mt-6 w-full "
        type="submit"
      >
        {state.submitEditPassword.flag && PasswordLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "تعديل كلمه المرور"
        )}
      </button>
    </form>
  );
}
function ImageForm({ values }) {
  const { studentID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitEditPhoto": {
        return {
          ...state,
          submitEditPhoto: action.payload,
        };
      }
      case "setUploadedImgSrc": {
        return {
          ...state,
          uploadedImgSrc: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitEditPhoto: {
      flag: "",
      dependency: false,
      data: {},
    },
    uploadedImgSrc: null,
  });

  const [EditPhotoSuccess, EditPhotoErrors, PhotoLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_PHOTO_API,
    "POST",
    state.submitEditPhoto.flag,
    state.submitEditPhoto.dependency,
    state.submitEditPhoto.data,
    true
  );
  useEffect(() => {
    if (EditPhotoSuccess) {
      URL.revokeObjectURL(state.uploadedImgSrc);
    }
  }, [EditPhotoSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: "onChange",
    values,
  });
  const imgInput = watch("profile_pic");
  useEffect(() => {
    const subscription = watch((input) => {
      if (input.profile_pic[0]?.name?.length > 0) {
        let imageUrl = URL.createObjectURL(input.profile_pic[0]);
        dispatch({
          type: "setUploadedImgSrc",
          payload: imageUrl,
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);
  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitEditPhoto",
      payload: {
        flag: "SubmitEditPhoto",
        dependency: !state.submitEditPhoto.dependency,
        data: { profile_pic: data.profile_pic[0], id: studentID },
      },
    });
  };
  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="w-[320px] flex   flex-col items-start justify-center gap-6 rounded-3xl bg-secondary text-white p-16 shadow-lg shadow-red-200/50 mt-24"
    >
      {/**  Profile Picture  */}
      <div className="w-full flex flex-col items-center">
        <div className="w-fit ">
          <label htmlFor="profile_pic">
            {" "}
            <div className="profile_pic h-36 aspect-square overflow-hidden rounded-full border-2 border-white p-1 cursor-pointer">
              <img
                className="h-full w-full rounded-full object-cover object-top"
                src={state.uploadedImgSrc || values?.profile_pic}
                alt="Student Avatar"
              />
            </div>{" "}
          </label>
        </div>
        <input
          id="profile_pic"
          hidden
          className="signin-inputs  w-full"
          type="file"
          accept="image/jpg, image/jpeg, image/png, image/webp"
          name="profile_pic"
          {...register("profile_pic", {
            required: true,
            validate: (value) => !(value[0]?.size > 5000000),
          })}
        />

        {errors.profile_pic && (
          <p className="pt-4 text-xs text-red-500">
            {errors.profile_pic.type === "required" &&
              "This field is required."}
            {errors.profile_pic.type === "validate" && "Max Image Size is 5MB."}
          </p>
        )}
        {
          //!-------Editing server errors -----

          EditPhotoErrors?.response?.data?.errors &&
            EditPhotoErrors?.response?.data?.errors?.profile_pic && (
              <p className="w-full text-end text-[12px] text-rewhite">
                {EditPhotoErrors?.response?.data.errors.profile_pic[0]}
              </p>
            )
        }
      </div>

      {/** Submit Button */}

      <button
        disabled={!isValid || (state.submitEditPhoto.flag && PhotoLoading)}
        className=" submit mt-6 w-full "
        type="submit"
      >
        {state.submitEditPhoto.flag && PhotoLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "تعديل الصورة الشخصية"
        )}
      </button>
    </form>
  );
}
function EditStudent() {
  const { studentID } = useParams();
  const [editStudentForm, setEditStudentForm] = useState("info");
  //!--------- get the student info for editing -------
  const [studentInfo, studentInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_STUDENT_INFO_API}${studentID}`,
    "GET",
    studentID,
    studentID
  );

  return (
    <section className="h-auto w-full overflow-y-auto  dark:bg-dark dark:text-light flex flex-col items-center ">
      <div className="filters flex w-full justify-center mt-24 gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
        {/* <button
          onClick={() => {
            setEditStudentForm("img");
          }}
          className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
            editStudentForm === "img" && "bg-secondary text-light"
          }`}
        >
          الصورة الشخصية
        </button> */}

        <button
          onClick={() => {
            setEditStudentForm("password");
          }}
          className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
            editStudentForm === "password" && "bg-secondary text-light"
          }`}
        >
          كلمه المرور
        </button>
        <button
          onClick={() => {
            setEditStudentForm("info");
          }}
          className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
            editStudentForm === "info" && "bg-secondary text-light"
          }`}
        >
          البيانات الأساسية{" "}
        </button>
      </div>
      {editStudentForm === "info" ? (
        <>
          <div className="w-[150px] h-[150px] mt-20">
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={studentID}
              viewBox={`0 0 256 256`}
            />
          </div>
          <Register edit StudentInfo={studentInfo} />
        </>
      ) : editStudentForm === "password" ? (
        <PasswordForm />
      ) : editStudentForm === "img" ? (
        // <ImageForm
        //   values={{
        //     profile_pic: studentInfo?.data[0]?.profile_pic,
        //   }}
        // />
        <></>
      ) : (
        ""
      )}
    </section>
  );
}

export default EditStudent;
