//!you may need to change record.key to record.key
import { Popconfirm, Table, Form, Input, Tag, Tooltip } from "antd";
import { useReducer, useEffect } from "react";
import axios from "axios";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import useWindowSize from "./useWindowSize";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState, useAuthDispatch } from "../GlobalContext.jsx";

function useTable(
  tableFor,
  nested,
  sessionID,
  dependency1,
  dependency2,
  params,
  ifExternal,
  externalData,
  refetch,
  setReFetch
) {
  const AuthState = useAuthState();
  const AuthDispatch = useAuthDispatch();

  const navigate = useNavigate();
  const { width } = useWindowSize();
  useEffect(() => {
    if (width && width < 800) {
      dispatch({ type: "setSmallWindow", payload: true });
    } else {
      dispatch({ type: "setSmallWindow", payload: false });
    }
  }, [width]);

  let columns;
  let api;

  switch (tableFor) {
    case "codes":
      api = process.env.REACT_APP_ALL_CODES_API;
      break;
    case "groups":
      api = process.env.REACT_APP_ALL_GROUPS_API;
      break;
    case "QuestionBank":
      api = process.env.REACT_APP_ALL_CATEGORIES_API;
      break;
    // TODO: change API
    case "studentQuestionBank":
      api = process.env.REACT_APP_ALL_CATEGORIES_API;
      break;
    case "attendance":
      api = process.env.REACT_APP_ADMIN_ATTENDANCE_TAB_API;
      break;

    case "assistants":
      api = process.env.REACT_APP_ALL_ASSISTANTS_API;
      break;

    case "students":
      api = `${process.env.REACT_APP_ADMIN_STUDENTS_TAB_API}${params}`;
      break;
    case "materials":
      api = `${process.env.REACT_APP_ADMIN_ALL_MATERIALS_API}${params}`;
      break;
    case "homeworkSets":
      api = `${process.env.REACT_APP_ADMIN_HOMEWORK_SETS_API}${params}`;
      break;
    case "quizSets":
      api = `${process.env.REACT_APP_ADMIN_QUIZ_SETS_API}${params}`;
      break;
    case "examSets":
      api = `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_SETS_API}${params}`;
      break;

    case "sessionAbsence":
      api = `${process.env.REACT_APP_ADMIN_SESSICONS_ABSENCE_API}${params.groupID}/${params.lectureID}`;
      break;

    case "lectures":
      api = `${process.env.REACT_APP_ADMIN_VIEW_LECTURE_TAB_API}/${params}`;
      break;

    case "purchaseHistory":
      api = `${process.env.REACT_APP_EDIT_STUDENT_PURCHASE_HISTORY_API}/${params}`;
      break;
    case "examsHistory":
      api = `${process.env.REACT_APP_EDIT_STUDENT_EXAMS_HISTORY_API}/${params}`;
      break;

    case "viewStudentExam":
      api = `${process.env.REACT_APP_ADMIN_VIEW_STUDENTS_EXAM_API}/${params}`;
      break;

    default:
      api = process.env.REACT_APP_ALL_ASSISTANTS_API;
      break;
  }

  function reducer(state, action) {
    switch (action.type) {
      case "setData": {
        return {
          ...state,
          data: action.payload,
        };
      }
      case "setForFilterData": {
        return {
          ...state,
          forFilterData: action.payload,
        };
      }

      case "SetLoading": {
        return {
          ...state,
          loading: action.payload,
        };
      }
      case "SetShowSidebar": {
        return {
          ...state,
          showSidebar: !state.showSidebar,
        };
      }

      case "SetEditRowKey": {
        return {
          ...state,
          editRowKey: action.payload,
        };
      }
      case "SetEditableQuizKey": {
        return {
          ...state,
          editableQuizKey: action.payload,
        };
      }
      case "SetSortingOptions": {
        return {
          ...state,
          sortingOptions: action.payload,
        };
      }
      case "SetQuery": {
        return {
          ...state,
          query: action.payload,
        };
      }
      case "SetColumnQuery": {
        return {
          ...state,
          columnQuery: action.payload,
        };
      }
      case "SetQueriedColumn": {
        return {
          ...state,
          queriedColumn: action.payload,
        };
      }
      case "SetFilteredInfo": {
        return {
          ...state,
          filteredInfo: action.payload,
        };
      }
      case "setSelectedRows": {
        return {
          ...state,
          selectedRows: action.payload,
        };
      }
      case "setSmallWindow": {
        return {
          ...state,
          smallWindow: action.payload,
        };
      }
      case "setUpdatingType": {
        return {
          ...state,
          updatingType: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const initialState = {
    data: [],
    forFilterData: [],
    loading: false,
    editRowKey: "",
    editableQuizKey: "",
    sortingOptions: {},
    query: "",
    columnQuery: "",
    queriedColumn: "",
    filteredInfo: {},
    selectedRows: [],
    smallWindow: false,
    updatingType: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  async function fetchData() {
    if (ifExternal) {
      dispatch({ type: "setData", payload: externalData });
      dispatch({ type: "setForFilterData", payload: externalData });

      dispatch({ type: "SetLoading", payload: false });
    } else {
      try {
        const res = await axios.get(api, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              AuthState.userData.student_token || AuthState.userData.admin_token
            }`,
            /* Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`, */
            //"Content-Type": "multipart/form-data",
          },
          //withCredentials: true,
        });
        const data = await res.data;

        dispatch({ type: "setData", payload: data.data });
        dispatch({ type: "setForFilterData", payload: data.data });

        dispatch({ type: "SetLoading", payload: false });
      } catch (error) {
        toast.error(
          error?.response?.data?.message || "حدث خطأ اثناء تحميل البيانات"
        );

        dispatch({ type: "SetLoading", payload: false });
        //!need to handle the case of delete the last item in the table and refetching again, so the refetch request will response with error no data and the last data will stay in the page but it must be removed whereas the item has deleted
        dispatch({ type: "setData", payload: [] });
      }
    }
  }

  useEffect(() => {
    dispatch({ type: "SetLoading", payload: true });

    fetchData();
  }, [dependency1, dependency2, externalData]);
  const [form] = Form.useForm();

  async function handleDelete(record) {
    let deleteApi;
    switch (tableFor) {
      case "codes":
        deleteApi = process.env.REACT_APP_DELETE_CODE_API;
        break;
      case "groups":
        deleteApi = process.env.REACT_APP_DELETE_GROUP_API;
        break;
      case "QuestionBank":
        deleteApi = process.env.REACT_APP_DELETE_CATEGORY_API;
        break;
      case "attendance":
        deleteApi = "https://groups-api.onrender.com/data";
        break;

      case "assistants":
        deleteApi = process.env.REACT_APP_DELETE_ASSISTANT_API;
        break;
      case "sessionAttendance":
        deleteApi =
          process.env
            .REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_TABLE_DELETE_ROW_API;
        break;

      case "students":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_API;
        break;
      case "materials":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_MATERIAL_API;
        break;
      case "homeworkSets":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_SET_API;
        break;
      case "quizSets":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_SET_API;
        break;
      case "examSets":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_SET_API;
        break;
      case "viewStudentExam":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_TRIAL_EXAM_API;
        break;

      case "lectures":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_SESSION_API;
        break;

      default:
        deleteApi = "https://students-api-g0pp.onrender.com/data";
        break;
    }

    const options = {
      url: deleteApi,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "students") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  async function handleRestore(record) {
    const options = {
      url: process.env.REACT_APP_ALL_DELETED_STUDENTS_RESTORE_API,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        fetchData();
        AuthDispatch({
          type: "setRefetchIfAcceptOrDeleteRequest",
        });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Something Wrong has Happened!"
      );
    }
  }
  /**
//!we check if the current id stored in the editRowKey is equal to the record id to know which row is going to be updated
 */

  function isEditing(record) {
    return record.key === state.editRowKey;
  }

  /**
//!here we reset the editRowKey to indicate that no row is going to be update and hide the cancel and save buttons then show the edit and delete buttons again 
 */

  async function handleUpdateRequest(data) {
    let editApi;
    let finalData = data;

    if (tableFor === "sessionAttendance") {
      if (state.updatingType === "quiz") {
        editApi =
          process.env
            .REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_UPDATE_STUDENT_QUIZ_DEGREE_API;
        const { id, student_quiz_degree } = data;
        finalData = { id: id, degree: student_quiz_degree };
      } else if (state.updatingType !== "quiz") {
        editApi =
          process.env
            .REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_UPDATE_STUDENT_HOMEWORK_API;
      }
    } else {
      switch (tableFor) {
        case "codes":
          editApi = process.env.REACT_APP_EDIT_CODE_API;
          break;
        case "groups":
          editApi = process.env.REACT_APP_EDIT_GROUP_API;
          break;
        case "QuestionBank":
          editApi = process.env.REACT_APP_EDIT_CATEGORY_API;
          break;
        case "attendance":
          editApi = "https://groups-api.onrender.com/data";
          break;

        case "assistants":
          editApi = process.env.REACT_APP_ALL_ASSISTANTS_API;
          break;
        case "homeworkSets":
          editApi = process.env.REACT_APP_ADMIN_EDIT_SET_API;
          break;
        case "quizSets":
          editApi = process.env.REACT_APP_ADMIN_EDIT_SET_API;
          break;
        case "examSets":
          editApi = process.env.REACT_APP_ADMIN_EDIT_SET_API;
          break;

        case "lectures":
          editApi = process.env.REACT_APP_ADMIN_EDIT_STUDENT_SESSION_API;
          break;

        default:
          editApi = "https://students-api-g0pp.onrender.com/data";
          break;
      }
    }
    const options = {
      url: editApi,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: finalData,
    };
    const res = await axios(options);
    return await res.data;
  }
  function handleCancelEdit() {
    dispatch({ type: "SetEditRowKey", payload: "" });
  }
  //!  value is for homework update from radio button ----
  async function handleSaveEdit(record, value) {
    const key = record.key;

    try {
      const row = value ? { homework: value } : await form.validateFields();
      const res = await handleUpdateRequest({ ...row, id: key });
      toast.success(res.message);
      dispatch({ type: "SetEditRowKey", payload: "" });
      dispatch({ type: "SetEditableQuizKey", payload: "" });
      dispatch({ type: "setUpdatingType", payload: "" });

      if (ifExternal) {
        setReFetch(!refetch);
      } else {
        fetchData();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
  //! -- to prevent sending request from onBlur of all editable inputs ---
  function handleUpdateQuiz(record) {
    if (state.editableQuizKey === record.key) {
      handleSaveEdit(record);
    }
  }
  function handleUpdateHomework(record, value) {
    handleSaveEdit(record, value);
  }

  function handleQuizDoubleClicked(record) {
    dispatch({ type: "SetEditableQuizKey", payload: record.key });
    dispatch({ type: "setUpdatingType", payload: "quiz" });

    handleEdit(record);
  }
  function handleEdit(record) {
    form.setFieldsValue({
      /* firstname: "",
      username: "",

      password: "",
      governorate: "",
      city: "",
      academiclevel: "",
      school: "",
      activationcode: "", */
      ...record,
    });

    dispatch({ type: "SetEditRowKey", payload: record.key });
  }
  function handleResetSortFilterSearch(record) {
    dispatch({ type: "SetSortingOptions", payload: {} });
    dispatch({
      type: "SetFilteredInfo",
      payload: {},
    });
    dispatch({
      type: "SetQuery",
      payload: "",
    });
    fetchData();
  }

  function editableCell({
    editing,
    dataIndex,
    title,
    record,
    children,
    ...restProps
  }) {
    const input = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            onBlur={() => handleUpdateQuiz(record)}
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[{ required: false }]}
          >
            {input}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  //!to save the sorting options and retrieve then reset it from reset button

  function handleTableChange(_, filter, sorter) {
    const { order, field } = sorter;
    dispatch({
      type: "SetSortingOptions",
      payload: { columnKey: field, order },
    });
    dispatch({
      type: "SetFilteredInfo",
      payload: filter,
    });
  }

  function handleGlobalSearch(e) {
    dispatch({ type: "SetQuery", payload: e.target.value });

    const filteredData = state?.forFilterData?.filter((item) => {
      return (
        Object.values(item)[0]
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase()) ||
        Object.values(item)[1]
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[2]
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[3]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[4]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[5]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[6]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[7]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[8]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[9]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[10]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[11]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[12]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[13]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[14]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    });

    dispatch({ type: "setData", payload: filteredData });
  }

  function handleColumnSearch(selectedKeys, confirm, dataIndex) {
    confirm({ closeDropdown: false });
    dispatch({ type: "SetColumnQuery", payload: selectedKeys[0] });
    dispatch({ type: "SetQueriedColumn", payload: dataIndex });
  }
  function handleColumnReset(clearFilters, confirm) {
    clearFilters();
    dispatch({ type: "SetColumnQuery", payload: "" });
    dispatch({ type: "SetQueriedColumn", payload: "" });
    confirm();
  }

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="flex h-40 w-72 flex-col justify-center gap-5 bg-light p-4">
        <Input
          className="signin-inputs search-table-placeholder text- h-10 pl-4 placeholder:!text-xl"
          autoFocus
          placeholder={` البحث في ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleColumnSearch(selectedKeys, confirm, dataIndex);
            }
          }}
        />
        <div className="flex justify-center gap-5 ">
          <button
            onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
            className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
          >
            بحث
          </button>
          <button
            onClick={() => handleColumnReset(clearFilters, confirm)}
            className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
          >
            حذف
          </button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <FontAwesomeIcon
        className={`${filtered ? "text-secondary" : "text-save"}`}
        icon={faMagnifyingGlass}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",

    render: (text) =>
      state.queriedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#f9b522", padding: 0 }}
          searchWords={[state.columnQuery]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  async function handleRetakeExam(record) {
    const options = {
      url: process.env.REACT_APP_ADMIN_RETAKE_TRIAL_EXAM_API,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "students") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  //!------------------ Columns --------------------

  const codesColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_delete")
                  )) && (
                  <Popconfirm
                    okText="تأكيد"
                    okType="danger"
                    cancelText="إلغاء"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="تأكيد اجراء الحذف؟"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      حذف
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_edit")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    تعديل
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="تأكيد"
                  okType="danger"
                  cancelText="إلغاء"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="تأكيد الغء التعديل؟"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-red-400 active:scale-90">
                    الغاء
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  حفظ
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title: "تاريخ الإنشاء",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "تاريخ الإنشاء"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "السعر",
      dataIndex: "price",
      filteredValue: state.filteredInfo.price || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("price", "السعر"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.price - b.price,
      sortOrder:
        state.sortingOptions.columnKey === "price" &&
        state.sortingOptions.order,
    },
    {
      title: "الكود",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "الكود"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 80,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];

  const groupsColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("group_delete")
                  )) && (
                  <Popconfirm
                    okText="تأكيد"
                    okType="danger"
                    cancelText="إلغاء"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="تأكيد اجراء الحذف؟"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      حذف
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("group_update")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    تعديل
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="تأكيد"
                  okType="danger"
                  cancelText="إلغاء"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="تأكيد الغء التعديل؟"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-red-400 active:scale-90">
                    الغاء
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  حفظ
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title: "تاريخ الإنشاء",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "تاريخ الإنشاء"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "الصف الدراسي",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("stage", "الصف الدراسي"),
      editTable: true,
      width: 150,
      align: "center",
      sorter: (a, b) => a.stage - b.stage,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },
    {
      title: "الوصف",
      dataIndex: "description",
      filteredValue: state.filteredInfo.description || null,
      ellipsis: {
        showTitle: false,
      },
      render: (description) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),

      //responsive: ["lg"],
      ...getColumnSearchProps("description", "الوصف"),
      editTable: true,
      width: 180,
      align: "center",
      sorter: (a, b) => a.description.length - b.description.length,
      sortOrder:
        state.sortingOptions.columnKey === "description" &&
        state.sortingOptions.order,
    },
    {
      title: "اسم الجروب",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "اسم الجروب"),
      editTable: true,
      width: 150,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const QuestionBankColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_deletecategory")
                  )) && (
                  <Popconfirm
                    okText="تأكيد"
                    okType="danger"
                    cancelText="إلغاء"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="تأكيد اجراء الحذف؟"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      حذف
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_updatecategory")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    تعديل
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="تأكيد"
                  okType="danger"
                  cancelText="إلغاء"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="تأكيد الغء التعديل؟"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-red-400 active:scale-90">
                    الغاء
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  حفظ
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title:
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? "التحكم"
          : "",
      dataIndex: "",
      width:
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? 350
          : 0,
      align: "center",
      //fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return (
          (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addquestion")
            )) && (
            <div className="m-0 flex  justify-center gap-10">
              <Link
                to={`/admin/question-bank/${record.key}/add-essay-question`}
                className="w-fit rounded-2xl border-2 border-green-700 bg-green-700 px-4 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-green-700 active:scale-90"
              >
                اضافة اسئلة مقالية
              </Link>
              <Link
                to={`/admin/question-bank/${record.key}/add-choose-question`}
                className="w-fit rounded-2xl border-2 border-accent bg-accent px-4 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              >
                اضافة اسئلة اختيارية
              </Link>
            </div>
          )
        );
      },
    },
    {
      title: "تاريخ الإنشاء",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "تاريخ الإنشاء"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "الإجمالي",
      dataIndex: "total",
      filteredValue: state.filteredInfo.total || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("total", "الإجمالي"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.total - b.total,
      sortOrder:
        state.sortingOptions.columnKey === "total" &&
        state.sortingOptions.order,
    },
    {
      title: "عدد الاسئلة المقالية",
      dataIndex: "n_essay",
      filteredValue: state.filteredInfo.n_essay || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("n_essay", "عدد الاسئلة المقالية"),
      width: 200,
      align: "center",
      sorter: (a, b) => a.n_essay - b.n_essay,
      sortOrder:
        state.sortingOptions.columnKey === "n_essay" &&
        state.sortingOptions.order,
    },
    {
      title: "صعب",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("hard", "صعب"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "متوسط",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("medium", "متوسط"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "سهل",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("easy", "سهل"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "الصف الدراسي",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("stage", "الصف الدراسي"),
      editTable: true,
      width: 150,
      align: "center",
      sorter: (a, b) => a.stage - b.stage,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },

    {
      title: "اسم التصميف",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "اسم التصميف"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
      render: (_, record) => {
        return AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("questionbank_viewquestionbycategory")
          ) ? (
          <Link to={`${record.key}`}>{record.name}</Link>
        ) : (
          <p>{record.name}</p>
        );
      },
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];

  const StudentQuestionBankColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_deletecategory")
                  )) && (
                  <Popconfirm
                    okText="تأكيد"
                    okType="danger"
                    cancelText="إلغاء"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="تأكيد اجراء الحذف؟"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      حذف
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_updatecategory")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    تعديل
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="تأكيد"
                  okType="danger"
                  cancelText="إلغاء"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="تأكيد الغء التعديل؟"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-red-400 active:scale-90">
                    الغاء
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  حفظ
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title:
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? "التحكم"
          : "",
      dataIndex: "",
      width:
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? 350
          : 0,
      align: "center",
      //fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return (
          (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addquestion")
            )) && (
            <div className="m-0 flex  justify-center gap-10">
              <Link
                to={`/admin/question-bank/${record.key}/add-essay-question`}
                className="w-fit rounded-2xl border-2 border-green-700 bg-green-700 px-4 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-green-700 active:scale-90"
              >
                اضافة اسئلة مقالية
              </Link>
              <Link
                to={`/admin/question-bank/${record.key}/add-choose-question`}
                className="w-fit rounded-2xl border-2 border-accent bg-accent px-4 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              >
                اضافة اسئلة اختيارية
              </Link>
            </div>
          )
        );
      },
    },
    {
      title: "تاريخ الإنشاء",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "تاريخ الإنشاء"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "الإجمالي",
      dataIndex: "total",
      filteredValue: state.filteredInfo.total || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("total", "الإجمالي"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.total - b.total,
      sortOrder:
        state.sortingOptions.columnKey === "total" &&
        state.sortingOptions.order,
    },
    {
      title: "عدد الاسئلة المقالية",
      dataIndex: "n_essay",
      filteredValue: state.filteredInfo.n_essay || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("n_essay", "عدد الاسئلة المقالية"),
      width: 200,
      align: "center",
      sorter: (a, b) => a.n_essay - b.n_essay,
      sortOrder:
        state.sortingOptions.columnKey === "n_essay" &&
        state.sortingOptions.order,
    },
    {
      title: "صعب",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("hard", "صعب"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "متوسط",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("medium", "متوسط"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "سهل",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("easy", "سهل"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "الصف الدراسي",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("stage", "الصف الدراسي"),
      editTable: true,
      width: 150,
      align: "center",
      sorter: (a, b) => a.stage - b.stage,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },

    {
      title: "اسم التصميف",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "اسم التصميف"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
      render: (_, record) => {
        return AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("questionbank_viewquestionbycategory")
          ) ? (
          <Link to={`${record.key}`}>{record.name}</Link>
        ) : (
          <p>{record.name}</p>
        );
      },
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];

  const AttendanceColumns = [
    {
      title: "الصف الدراسي",
      dataIndex: "academiclevel",
      width: 150,
      align: "center",
      editTable: true,
      sorter: (a, b) => a.academiclevel.length - b.academiclevel.length,
      sortOrder:
        state.sortingOptions.columnKey === "academiclevel" &&
        state.sortingOptions.order,
      filters: [
        { text: "الصف الأاول", value: "الصف الأاول" },
        { text: "الصف الثاني", value: "الصف الثاني" },
        { text: "الصف الثالث", value: "الصف الثالث" },
      ],
      filteredValue: state.filteredInfo.academiclevel || null,
      onFilter: (value, record) => String(record.academiclevel).includes(value),
      render: (tag) => {
        const color = tag.includes("First")
          ? "#b12323"
          : tag.includes("Second")
          ? "#f9b522"
          : "#1890ff";
        return (
          <Tag color={color} key={tag}>
            {tag}
          </Tag>
        );
      },
    },
    {
      title: "اسم الجروب",
      dataIndex: "groupname",
      filteredValue: state.filteredInfo.groupname || null,

      ...getColumnSearchProps("groupname", "اسم الجروب"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.groupname.length - b.groupname.length,
      sortOrder:
        state.sortingOptions.columnKey === "groupname" &&
        state.sortingOptions.order,
    },

    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 80,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];

  const assistantsColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            <Popconfirm
              okText="تأكيد"
              okType="danger"
              cancelText="إلغاء"
              color="#fecaca"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleDelete(record)}
              title="هل تريد بالفعل حذف بيانات المساعد؟"
            >
              <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                حذف
              </button>
            </Popconfirm>

            <button
              className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              onClick={() =>
                navigate(`/admin/assistants/edit-assistant/${record.key}`)
              }
            >
              تعديل
            </button>
          </div>
        ) : null;
      },
    },
    {
      title: "الصورة",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (_, record) => (
        <div className="profile_pic h-28 w-28 overflow-hidden rounded-full border-2 border-secondary p-1">
          <img
            className="h-full w-full rounded-full object-cover object-top"
            src={record.profile_pic}
            alt={record.name}
          />
        </div>
      ),
    },
    {
      title: "رقم الهاتف",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,

      ...getColumnSearchProps("mobile", "رقم الهاتف"),
      editTable: false,
    },

    {
      title: "الإسم",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      /**
      *  ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      */
      //responsive: ["lg"],
      ...getColumnSearchProps("name", "الإسم"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const materialsColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture_addmatrial")
              )) && (
              <Popconfirm
                okText="تأكيد"
                okType="danger"
                cancelText="إلغاء"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDelete(record)}
                title="هل تريد بالفعل حذف الملف ؟"
              >
                <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                  حذف
                </button>
              </Popconfirm>
            )}
            <a
              className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              href={record.address}
              rel="noreferrer"
              target="_blank"
            >
              تحميل
            </a>
          </div>
        ) : null;
      },
    },

    {
      title: "الحالة",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (_, record) => (
        <p>
          {record.is_public === 1
            ? "مجاني"
            : record.is_public === 0
            ? "مدفوع"
            : ""}
        </p>
      ),
    },

    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const sessionAttendanceColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 90,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <Popconfirm
            okText="تأكيد"
            okType="danger"
            cancelText="إلغاء"
            color="#fecaca"
            //okButtonProps={{ loading: state.loading }}
            onConfirm={() => handleDelete(record)}
            title="هل تريد بالفعل الحذف؟  "
          >
            <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
              حذف
            </button>
          </Popconfirm>
        ) : null;
      },
    },

    {
      title: "الكود",
      dataIndex: "code",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.code || null,

      ...getColumnSearchProps("code", "الكود"),
      editTable: false,
    },
    {
      title: "تاريخ الحضور",
      dataIndex: "attend_at",
      width: 170,
      align: "center",
      filteredValue: state.filteredInfo.attend_at || null,

      ...getColumnSearchProps("attend_at", "تاريخ الحضور"),
      editTable: false,
    },
    {
      title: "الواجب",
      dataIndex: "student_quiz_degree",
      width: 150,
      align: "center",
      render: (_, record) => (
        <div className="profile_pic flex h-auto  w-full flex-col items-center  justify-start gap-2">
          <label
            className={`flex w-full cursor-pointer items-center justify-between rounded-md px-2 transition-all duration-300 ease-in-out hover:bg-secondary hover:text-light ${
              record.homework === 1 && "bg-green-500 text-light"
            }`}
            htmlFor={`yes_${record.key}`}
          >
            <input
              onChange={(e) => handleUpdateHomework(record, e.target.value)}
              className="h-8 w-8"
              type="radio"
              name={record.key}
              value="1"
              id={`yes_${record.key}`}
              defaultChecked={record.homework === 1}
            />
            <span>نعم</span>
          </label>
          <label
            className={`flex w-full cursor-pointer items-center justify-between rounded-md px-2 transition-all duration-300 ease-in-out hover:bg-secondary hover:text-light ${
              record.homework === 0 && "bg-secondary text-light"
            }`}
            htmlFor={`no${record.key}`}
          >
            <input
              onChange={(e) => handleUpdateHomework(record, e.target.value)}
              className="h-8 w-8"
              type="radio"
              name={record.key}
              value="0"
              id={`no${record.key}`}
              defaultChecked={record.homework === 0}
            />
            <span>لا</span>
          </label>
          <label
            className={`flex w-full cursor-pointer items-center justify-between rounded-md px-2 transition-all duration-300 ease-in-out hover:bg-secondary hover:text-light ${
              record.homework === 2 && "bg-accent text-light"
            }`}
            htmlFor={`not_Completed${record.key}`}
          >
            <input
              onChange={(e) => handleUpdateHomework(record, e.target.value)}
              className="h-8 w-8"
              type="radio"
              name={record.key}
              value="2"
              id={`not_Completed${record.key}`}
              defaultChecked={record.homework === 2}
            />
            <span>لم يكتمل</span>
          </label>
        </div>
      ),
    },
    {
      title: "درجة الطالب",
      dataIndex: "student_quiz_degree",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.student_quiz_degree || null,
      ...getColumnSearchProps("student_quiz_degree", "درجة الطالب"),
      editTable: true,
      render: (_, record) => (
        <div className="h-full w-full">
          {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("attendance_view")
          ) ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("attendance_viewnumberofstudent")
          ) ? (
            <div
              className="flex h-16 w-full cursor-pointer select-none items-center justify-center border-2 border-secondary"
              onDoubleClick={() =>
                record.quiz_degree === null
                  ? null
                  : handleQuizDoubleClicked(record)
              }
            >
              {record.student_quiz_degree}
            </div>
          ) : (
            <div className="cursor-pointer select-none ">
              {record.student_quiz_degree}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "درجة الامتحان",
      dataIndex: "quiz_degree",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.quiz_degree || null,
      ...getColumnSearchProps("quiz_degree", "درجة الامتحان"),
      editTable: false,
    },

    {
      title: "هاتف ولي الامر ",
      dataIndex: "parent_mobile",
      filteredValue: state.filteredInfo.parent_mobile || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("parent_mobile", "هاتف الوالد 1"),
      editTable: false,
      width: 180,
      align: "center",
      sorter: (a, b) => a.parent_mobile - b.parent_mobile,
      sortOrder:
        state.sortingOptions.columnKey === "parent_mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "الموبايل",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("mobile", "الموبايل"),
      editTable: false,
      width: 140,
      align: "center",
      sorter: (a, b) => a.mobile - b.mobile,
      sortOrder:
        state.sortingOptions.columnKey === "mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "اسم الطالب",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "اسم الطالب"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const studentHistoryColumns = [
    {
      title: "تاريخ الحضور",
      dataIndex: "attend_at",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.attend_at || null,
      ...getColumnSearchProps("attend_at", "تاريخ الحضور"),
      editTable: false,
    },
    {
      title: "المكان",
      dataIndex: "location",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.location || null,
      ...getColumnSearchProps("location", "المكان"),
      editTable: false,
    },
    {
      title: "اسم المحاضرة",
      dataIndex: "lecture_name",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.lecture_name || null,
      ...getColumnSearchProps("lecture_name", "اسم المحاضرة"),
      editTable: false,
    },
  ];
  const studentsColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 250,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-4">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_delete")
              )) && (
              <Popconfirm
                okText="تأكيد"
                okType="danger"
                cancelText="إلغاء"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDelete(record)}
                title="هل تريد بالفعل حذف بيانات الطالب"
              >
                <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                  حذف
                </button>
              </Popconfirm>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_edit")
              )) && (
              <button
                className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                onClick={() =>
                  navigate(`/admin/students/edit-student/${record.key}`)
                }
              >
                تعديل
              </button>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_view")
              )) && (
              <button
                className="w-fit px-2 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                onClick={() =>
                  navigate(`/admin/students/view-student/${record.key}`)
                }
              >
                عرض
              </button>
            )}
          </div>
        ) : null;
      },
    },

    {
      title: "تاريخ الإنشاء",
      dataIndex: "created_at",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.created_at || null,

      ...getColumnSearchProps("created_at", "تاريخ الإنشاء"),
      editTable: false,
    },
    {
      title: "أضيف بواسطة",
      dataIndex: "approved_by",
      filteredValue: state.filteredInfo.approved_by || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("approved_by", "أضيف بواسطة"),
      editTable: false,
      width: 150,
      align: "center",
      sorter: (a, b) => a.approved_by.length - b.approved_by.length,
      sortOrder:
        state.sortingOptions.columnKey === "approved_by" &&
        state.sortingOptions.order,
    },
    {
      title: "كارت الحضور",
      dataIndex: "attendance_card",
      width: 150,
      align: "center",
    },
    {
      title: "الكود",
      dataIndex: "code",
      width: 150,
      align: "center",
    },
    {
      title: "الجروب",
      dataIndex: "group",
      width: 100,
      align: "center",
    },
    {
      title: "مكان الحضور",
      dataIndex: "type",
      width: 150,
      align: "center",
    },
    {
      title: "المدرسة",
      dataIndex: "school",
      width: 150,
      align: "center",
    },

    {
      title: "هاتف ولي الأمر ",
      dataIndex: "parent_mobile",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile || null,

      ...getColumnSearchProps("parent_mobile", "رقم الهاتف"),
      editTable: false,
    },
    {
      title: "الموبايل",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,

      ...getColumnSearchProps("mobile", "الموبايل"),
      editTable: false,
    },

    {
      title: "الإسم",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "الإسم"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const homeworkSetsColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",

      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addHw")
                  )) && (
                  <Popconfirm
                    okText="تأكيد"
                    okType="danger"
                    cancelText="إلغاء"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="تأكيد اجراء الحذف؟"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      حذف
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addHw")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    تعديل
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="تأكيد"
                  okType="danger"
                  cancelText="إلغاء"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="تأكيد الغء التعديل؟"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-red-400 active:scale-90">
                    الغاء
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  حفظ
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title: "صعب",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      ...getColumnSearchProps("hard", "صعب"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "متوسط",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      ...getColumnSearchProps("medium", "متوسط"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "سهل",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      ...getColumnSearchProps("easy", "سهل"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "الإسم",
      dataIndex: "category_name",
      filteredValue: state.filteredInfo.category_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("category_name", "الإسم"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.category_name.length - b.category_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "category_name" &&
        state.sortingOptions.order,
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const quizSetsColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",

      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <Popconfirm
                    okText="تأكيد"
                    okType="danger"
                    cancelText="إلغاء"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="تأكيد اجراء الحذف؟"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      حذف
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    تعديل
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="تأكيد"
                  okType="danger"
                  cancelText="إلغاء"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="تأكيد الغء التعديل؟"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-red-400 active:scale-90">
                    الغاء
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  حفظ
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title: "صعب",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      ...getColumnSearchProps("hard", "صعب"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "متوسط",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      ...getColumnSearchProps("medium", "متوسط"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "سهل",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      ...getColumnSearchProps("easy", "سهل"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "الإسم",
      dataIndex: "category_name",
      filteredValue: state.filteredInfo.category_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("category_name", "الإسم"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.category_name.length - b.category_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "category_name" &&
        state.sortingOptions.order,
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const examSetsColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",

      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <Popconfirm
                    okText="تأكيد"
                    okType="danger"
                    cancelText="إلغاء"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="تأكيد اجراء الحذف؟"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      حذف
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    تعديل
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="تأكيد"
                  okType="danger"
                  cancelText="إلغاء"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="تأكيد الغء التعديل؟"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-red-400 active:scale-90">
                    الغاء
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  حفظ
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title: "صعب",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      ...getColumnSearchProps("hard", "صعب"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "متوسط",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      ...getColumnSearchProps("medium", "متوسط"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "سهل",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      ...getColumnSearchProps("easy", "سهل"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "الإسم",
      dataIndex: "category_name",
      filteredValue: state.filteredInfo.category_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("category_name", "الإسم"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.category_name.length - b.category_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "category_name" &&
        state.sortingOptions.order,
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const sessionAbsenceColumns = [
    // {
    //   title: "هاتف الوالد 2",
    //   dataIndex: "parent_mobile2",
    //   width: 130,
    //   align: "center",
    //   filteredValue: state.filteredInfo.parent_mobile2 || null,

    //   ...getColumnSearchProps("parent_mobile2", "رقم الهاتف"),
    //   editTable: false,
    // },
    {
      title: "هاتف الوالد 1",
      dataIndex: "parent_mobile",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile || null,

      ...getColumnSearchProps("parent_mobile", "رقم الهاتف"),
      editTable: false,
    },
    {
      title: "رقم الهاتف",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,

      ...getColumnSearchProps("mobile", "رقم الهاتف"),
      editTable: false,
    },

    {
      title: "الإسم",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "الإسم"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];

  const lecturesColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 250,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_deletecategory")
                  )) && (
                  <Popconfirm
                    okText="تاكيد"
                    okType="danger"
                    cancelText="الغاء"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="هل انت متاكد من الحذف؟"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      حذف
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_updatecategory")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    تعديل
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title=" هل تريد إلغاء التعديل؟"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    الغاء
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  حفظ
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title: "تم شراؤها عن طريق",
      dataIndex: "bought_by",
      width: 200,
      align: "center",
    },
    {
      title: "عدد المشاهدات",
      dataIndex: "views",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "المحاضره تنتهي في",
      dataIndex: "expire_at",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "درحه الواجب",
      dataIndex: "homework_degree",
      width: 200,
      align: "center",

      render: (_, record) => {
        return (
          <p>
            {record.std_homework_degree &&
              `${record.std_homework_degree}/${record.homework_degree}`}
            {}
          </p>
        );
      },
    },
    {
      title: "درحه الامتحان",
      dataIndex: "quiz_degree",
      width: 200,
      align: "center",

      render: (_, record) => {
        return (
          <p>
            {record.std_quiz_degree &&
              `${record.std_quiz_degree}/${record.quiz_degree}`}
            {}
          </p>
        );
      },
    },
    {
      title: "رقم الطالب",
      dataIndex: "mobile",
      width: 200,
      align: "center",
    },
    {
      title: "اسم الطالب",
      dataIndex: "student_name",
      filteredValue: state.filteredInfo.student_name || null,
      ...getColumnSearchProps("student_name", "اسم الطالب"),
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "student_name" &&
        state.sortingOptions.order,
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      editTable: false,
      width: 80,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "الترقيم"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const purchaseHistoryColumns = [
    {
      title: "التاريخ ",
      dataIndex: "time",
      filteredValue: state.filteredInfo.time || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("time", "التاريخ"),
      width: 120,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "time" && state.sortingOptions.order,
    },
    {
      title: "مكان الحضور ",
      dataIndex: "location",

      ...getColumnSearchProps("location", "مكان الحضور"),
      width: 120,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "location" &&
        state.sortingOptions.order,
    },
    {
      title: "الواجب",
      dataIndex: "homework_student",
      filteredValue: state.filteredInfo.homework_student || null,
      ...getColumnSearchProps("homework_student", "الواجب"),
      width: 100,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "homework_student" &&
        state.sortingOptions.order,

      render: (_, record) => {
        return (
          <p>
            {record.is_center === 1 &&
              `${record.homework_student ? record.homework_student : ""}`}
            {record.homework_student &&
              record.is_center === 0 &&
              `${record.student_quiz}/${record.homework_degree}`}
            {}
          </p>
        );
      },
    },
    {
      title: "الامتحان",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "الامتحان"),
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,

      render: (_, record) => {
        return (
          <p>
            {record.student_quiz &&
              `${record.quiz_degree}/${record.student_quiz}`}
            {}
          </p>
        );
      },
    },
    {
      title: "اسم المحاضره",
      dataIndex: "lecture_name",
      filteredValue: state.filteredInfo.lecture_name || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("lecture_name", "اسم المحاضره"),
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "lecture_name" &&
        state.sortingOptions.order,
    },
  ];
  const examsHistoryColumns = [
    {
      title: "النسبة المئوية ",
      dataIndex: "percentage",

      ...getColumnSearchProps("percentage", "النسبة المئوية"),
      width: 200,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "percentage" &&
        state.sortingOptions.order,

      render: (_, record) => {
        return <p>{record.percentage}%</p>;
      },
    },
    {
      title: "الدرجة",
      dataIndex: "degree",
      filteredValue: state.filteredInfo.degree || null,
      ...getColumnSearchProps("degree", "الدرجة"),
      width: 100,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "degree" &&
        state.sortingOptions.order,
    },
    {
      title: "الكود",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("code", "الكود"),
      width: 200,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },
    {
      title: "اسم الامتحان",
      dataIndex: "exam_name",
      filteredValue: state.filteredInfo.exam_name || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("exam_name", "اسم المحاضره"),
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "exam_name" &&
        state.sortingOptions.order,
    },
  ];
  const viewStudentExamColumns = [
    {
      title: "الخيارات",
      dataIndex: "",
      width: 230,
      align: "center",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 w-full flex  justify-center gap-6">
            <Popconfirm
              okText="تأكيد"
              okType="danger"
              cancelText="إلغاء"
              color="#fecaca"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleDelete(record)}
              title="تأكيد اجراء الحذف؟"
            >
              <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                حذف
              </button>
            </Popconfirm>
            <Popconfirm
              okText="تأكيد"
              okType="danger"
              cancelText="إلغاء"
              color="#fecaca"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleRetakeExam(record)}
              title="تأكيد إعادة الاختبار؟ "
            >
              <button className="w-60 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                اعادة الإختبار
              </button>
            </Popconfirm>
          </div>
        ) : null;
      },
    },
    {
      title: "النسبة المئوية",
      dataIndex: "percentage",
      width: 150,
      align: "center",
    },
    {
      title: "أنشئ في",
      dataIndex: "created_at",
      width: 150,
      align: "center",
    },
    {
      title: "الدرجة",
      dataIndex: "degree",
      width: 150,
      align: "center",
    },
    {
      title: "الكود",
      dataIndex: "code",
      width: 150,
      align: "center",
    },
    // {
    //   title: "2 رقم ولي الأمر",
    //   dataIndex: "parent_mobile2",
    //   width: 150,
    //   align: "center",
    // },
    {
      title: "1 رقم ولي الأمر",
      dataIndex: "parent_mobile",
      width: 150,
      align: "center",
    },
    {
      title: "رقم الطالب",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("mobile", "رقم الطالب"),
      width: 150,
      align: "center",
    },
    {
      title: "اسم الطالب",
      dataIndex: "student_name",
      filteredValue: state.filteredInfo.student_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("student_name", "اسم الطالب"),
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.student_name.length - b.student_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "student_name" &&
        state.sortingOptions.order,
    },
    {
      title: "الترقيم",
      dataIndex: "n",
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      ...getColumnSearchProps("n", "الترقيم"),
    },
  ];
  //!----------Nested Table for attendance page

  const expandedRow = (row) => {
    const nestedSessionColumns = [
      {
        title: "الحضور",
        width: 110,
        align: "center",
        //filteredValue: state.filteredInfo.email || null,
        editTable: false,
        render: (_, record) => {
          return (
            <Link
              className="w-[150px] rounded-2xl border-2 border-accent bg-accent px-3 py-0 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              to={`/admin/attendance/${row.key}/${record.key}`}
            >
              عرض{" "}
            </Link>
          );
        },
      },
      {
        title: "عدد الطلاب",
        dataIndex: "studentsnum",
        width: 50,
        align: "center",
        filteredValue: state.filteredInfo.studentsnum || null,

        ...getColumnSearchProps("studentsnum", "عدد الطلاب"),
        sorter: (a, b) => a.studentsnum - b.studentsnum,
        sortOrder:
          state.sortingOptions.columnKey === "studentsnum" &&
          state.sortingOptions.order,
      },
      {
        title: "اسم الحصة",
        dataIndex: "sessionname",
        filteredValue: state.filteredInfo.sessionname || null,

        //responsive: ["lg"],
        ...getColumnSearchProps("sessionname", "اسم الحصة"),
        editTable: true,
        width: 200,
        fixed: state.smallWindow ? false : "right",
        align: "center",
        sorter: (a, b) => a.sessionname.length - b.sessionname.length,
        sortOrder:
          state.sortingOptions.columnKey === "sessionname" &&
          state.sortingOptions.order,
      },
    ];

    const nestedLecturesColumns = [
      {
        title: "الحضور",
        width: 110,
        align: "center",
        //filteredValue: state.filteredInfo.email || null,
        editTable: false,
        render: (_, record) => {
          return (
            <Link
              className="w-[150px] rounded-2xl border-2 border-accent bg-accent px-3 py-0 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              to={`/admin/attendance/${row.key}/${record.key}`}
            >
              عرض{" "}
            </Link>
          );
        },
      },
      {
        title: "عدد الطلاب",
        dataIndex: "studentsnum",
        width: 50,
        align: "center",
        filteredValue: state.filteredInfo.studentsnum || null,

        ...getColumnSearchProps("studentsnum", "عدد الطلاب"),
        sorter: (a, b) => a.studentsnum - b.studentsnum,
        sortOrder:
          state.sortingOptions.columnKey === "studentsnum" &&
          state.sortingOptions.order,
      },
      {
        title: "اسم الحصة",
        dataIndex: "sessionname",
        filteredValue: state.filteredInfo.sessionname || null,

        //responsive: ["lg"],
        ...getColumnSearchProps("sessionname", "اسم الحصة"),
        editTable: true,
        width: 200,
        fixed: state.smallWindow ? false : "right",
        align: "center",
        sorter: (a, b) => a.sessionname.length - b.sessionname.length,
        sortOrder:
          state.sortingOptions.columnKey === "sessionname" &&
          state.sortingOptions.order,
      },
    ];

    let sessionData;
    let rowKey = row.n - 1;
    sessionData = state.data[rowKey].sessions;

    return (
      <Table
        columns={
          tableFor === "lectures" ? nestedLecturesColumns : nestedSessionColumns
        }
        dataSource={sessionData}
        pagination={false}
      />
    );
  };

  switch (tableFor) {
    case "codes":
      columns = codesColumns;
      break;
    case "groups":
      columns = groupsColumns;
      break;
    case "QuestionBank":
      columns = QuestionBankColumns;
      break;
    case "studentQuestionBank":
      columns = StudentQuestionBankColumns;
      break;
    case "attendance":
      columns = AttendanceColumns;
      break;

    case "assistants":
      columns = assistantsColumns;
      break;
    case "students":
      columns = studentsColumns;
      break;
    case "sessionAttendance":
      columns = sessionAttendanceColumns;
      break;
    case "sessionAbsence":
      columns = sessionAbsenceColumns;
      break;
    case "studentHistory":
      columns = studentHistoryColumns;
      break;
    case "materials":
      columns = materialsColumns;
      break;
    case "homeworkSets":
      columns = homeworkSetsColumns;
      break;
    case "quizSets":
      columns = quizSetsColumns;
      break;
    case "examSets":
      columns = examSetsColumns;
      break;
    case "lectures":
      columns = lecturesColumns;
      break;
    case "purchaseHistory":
      columns = purchaseHistoryColumns;
      break;
    case "examsHistory":
      columns = examsHistoryColumns;
      break;
    case "viewStudentExam":
      columns = viewStudentExamColumns;
      break;

    default:
      columns = codesColumns;
      break;
  }
  const editableColumns = columns.map((col) => {
    if (!col.editTable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // set sellection in table
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      dispatch({ type: "setSelectedRows", payload: selectedRows });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  //! buttons functions
  const handelSendAbsence = () => {
    let finalData = {
      lecture_id: params.lectureID,
      group_id: params.groupID,
      selected: state.selectedRows,
    };
    async function fetchData() {
      try {
        let res = await axios.post(
          process.env.REACT_APP_ADMIN_SEND_ABSENCE_API,
          finalData,
          {
            //TODO: FIX THIS
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.userData).admin_token
              }`,
            },
          }
        );
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
    fetchData();
  };

  const handelDeleteSelection = () => {};
  const table = (
    <section>
      <div className="table-options  flex items-center justify-between gap-10 md:flex-col md:items-center">
        <div className="export-reset flex w-1/2 items-center justify-start gap-10 md:w-full md:flex-col md:items-center">
          {state?.data && (
            <button className="w-fit md:w-full flex items-center justify-center rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
              <CSVLink data={state?.data} filename={`${tableFor} excel.csv`}>
                استخراج
              </CSVLink>
            </button>
          )}
          <button
            className="w-fit md:w-full flex items-center justify-center rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
            onClick={handleResetSortFilterSearch}
          >
            اعادة تهيئة الجدول
          </button>
        </div>
        <div className="search-wrapper w-1/2 md:w-full">
          <input
            dir="rtl"
            type="search"
            className="signin-inputs my-7 pl-4 !text-start !border-secondary !placeholder-secondary"
            placeholder="ابحث..."
            onChange={handleGlobalSearch}
            value={state.query}
          />
        </div>
      </div>

      <div className=" w-full  overflow-hidden rounded-xl border-2  border-secondary">
        <Form form={form} component={false}>
          <Table
            columns={editableColumns}
            dataSource={state.data}
            components={{ body: { cell: editableCell } }}
            bordered
            onChange={handleTableChange}
            loading={state.loading}
            expandedRowRender={nested ? expandedRow : ""}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            pagination={{ position: ["bottomCenter"] }}
            // rowSelection={{
            //   type: "checkbox",
            //   selectedRowKeys: state.selectedRows,
            //   onChange: (keys) => {
            //     dispatch({
            //       type: "setSelectedRows",
            //       payload: keys,
            //     });
            //   },
            //   selections: [
            //     Table.SELECTION_ALL,
            //     Table.SELECTION_NONE,
            //     Table.SELECTION_INVERT,
            //     {
            //       key: "even",
            //       text: "Select Even Rows",
            //       onSelect: (allKeys) => {
            //         const selectedKeys = allKeys?.filter(
            //           (key) => key % 2 === 0
            //         );
            //         dispatch({
            //           type: "setSelectedRows",
            //           payload: selectedKeys,
            //         });
            //       },
            //     },
            //     {
            //       key: "odd",
            //       text: "Select Odd Rows",
            //       onSelect: (allKeys) => {
            //         const selectedKeys = allKeys?.filter(
            //           (key) => key % 2 !== 0
            //         );
            //         dispatch({
            //           type: "setSelectedRows",
            //           payload: selectedKeys,
            //         });
            //       },
            //     },
            //     {
            //       key: "First Level",
            //       text: "Select First Level Students",
            //       onSelect: (allKeys) => {
            //         const selectedKeys = allKeys?.filter((key) => {
            //           const matched = state.data.find((item) => {
            //             return (
            //               item.key === key && item.academiclevel === "First"
            //             );
            //           });
            //           return matched;
            //         });
            //         dispatch({
            //           type: "setSelectedRows",
            //           payload: selectedKeys,
            //         });
            //       },
            //     },
            //   ],
            // }}
            scroll={{
              y: 540,
            }}

            /**expandable={{
              expandedRowRender: (record) => <p> {record.school}</p>,
              rowExpandable: (record) => record.school !== "Not Expandable",
            }}*/
          />
        </Form>
      </div>
      {tableFor === "sessionAbsence" && (
        <div className="mt-4 flex w-1/2 items-center justify-start gap-10 md:w-full md:flex-col md:items-center">
          <button
            className="w-fit rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
            disabled={state.selectedRows.length <= 0}
            onClick={handelSendAbsence}
          >
            إرسال الغياب إلي أولياء الأمور
          </button>
        </div>
      )}
    </section>
  );
  return table;
}

export default useTable;
