import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

const AdminChangePassword = () => {
  const [hide, setHide] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  //!--------- add edit assistant -------

  const [
    EditAdminPasswordSuccess,
    EditAdminPasswordErrors,
    EditAdminPasswordLoading,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_CHANGE_PROFILE_PASSWORD_API,
    "POST",
    formFlag,
    formDependency,
    formValues
  );

  const onSubmit = (formData) => {
    setFormValues(formData);
    setFormFlag("EditAssistantPassword");
    setFormDependency(true);
  };

  useEffect(() => {
    if (EditAdminPasswordSuccess) {
      toast.success("Update Password Successfully..");
      setFormFlag("");
      setFormDependency(false);
      reset();
    }
  }, [EditAdminPasswordSuccess]);

  useEffect(() => {
    if (EditAdminPasswordErrors) {
      toast.error(EditAdminPasswordErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditAdminPasswordErrors]);

  return (
    <div className="h-auto width flex flex-col items-center">
      <h4 className="text-3xl font-bold">تعديل كلمه المرور</h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-start  bg-secondary text-white p-16  rounded-3xl shadow-lg shadow-blue/50 w-3/4 md:w-full my-6 gap-6"
      >
        {/** Password  */}

        <div className="w-full">
          <div className="relative flex flex-col justify-center items-start gap-2 ">
            <label htmlFor="password">كلمه المرور</label>
            <input
              id="password"
              className="signin-inputs w-full"
              type={`${hide ? "text" : "password"}`}
              placeholder="كلمه المرور"
              name="password"
              autoComplete="on"
              {...register("password", {
                required: true,
                maxLength: 25,
                minLength: 6,
              })}
            />
            {hide ? (
              <FontAwesomeIcon
                onClick={() => setHide(!hide)}
                className="absolute left-2 bottom-2 w-8 h-8 cursor-pointer"
                icon={faEye}
              />
            ) : (
              <FontAwesomeIcon
                onClick={() => setHide(!hide)}
                className="absolute left-2 bottom-2 w-8 h-8 cursor-pointer"
                icon={faEyeSlash}
              />
            )}
          </div>
          {errors.password && (
            <p className="text-white text-lg pt-4">
              {errors.password.type === "required" && "هذه الخانة مطلوبه."}
              {errors.password.type === "maxLength" &&
                "الحد الأقصى لطول كلمة المرور هو 25 حرفًا."}
              {errors.password.type === "minLength" &&
                "الحد الأدنى لطول كلمة المرور هو 6 أحرف.."}
            </p>
          )}
        </div>

        {/** Confirm Password  */}

        <div className="w-full">
          <div className="relative flex flex-col justify-center items-start gap-2 ">
            <label htmlFor="password_confirmation">تأكيد كلمه المرور</label>
            <input
              id="password_confirmation"
              className="signin-inputs w-full"
              type={`${hide ? "text" : "password"}`}
              placeholder="تأكيد كلمه المرور"
              name="password_confirmation"
              autoComplete="on"
              {...register("password_confirmation", {
                required: true,
                maxLength: 25,
                minLength: 6,
                validate: (value) => value === getValues("password"),
              })}
            />
            {hide ? (
              <FontAwesomeIcon
                onClick={() => setHide(!hide)}
                className="absolute left-2 bottom-2 w-8 h-8 cursor-pointer"
                icon={faEye}
              />
            ) : (
              <FontAwesomeIcon
                onClick={() => setHide(!hide)}
                className="absolute left-2 bottom-2 w-8 h-8 cursor-pointer"
                icon={faEyeSlash}
              />
            )}
          </div>
          {errors.password_confirmation && (
            <p className="text-white text-lg pt-4">
              {errors.password_confirmation.type === "required" &&
                "هذه الخانة مطلوبه."}
              {errors.password_confirmation.type === "maxLength" &&
                "كلمة المرور_تأكيد الحد الأقصى للطول هو 25 حرفًا."}
              {errors.password_confirmation.type === "minLength" &&
                "الحد الأدنى لطول كلمة المرور هو 6 أحرف."}
              {errors.password_confirmation.type === "validate" &&
                "كلمات المرور غير متطابقة."}
            </p>
          )}
        </div>

        <input className=" submit max-w-60 mt-10" type="submit" value="تعديل" />
      </form>
    </div>
  );
};

export default AdminChangePassword;
